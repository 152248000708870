import { isLaterThanOrEqualTo } from 'utils/datetime'

const USE_NEW_PANDUAN_URL = isLaterThanOrEqualTo(
  new Date(2025, 0, 1), // Update this date when the new panduan is ready to be used, default to January 1, 2025
  new Date()
)

// Used in Beranda, and Kepala Dinas page
export const PANDUAN_GENERAL_URL = USE_NEW_PANDUAN_URL
  ? 'http://s.id/PengelolaanKinerja2025'
  : 'https://linktr.ee/pengelolaankinerjapmm'

// Used in Sidebar and some other pages to serve general purpose
export const PANDUAN_PENGELOLAAN_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/categories/40231355098649-Pengelolaan-Kinerja'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/categories/26036867121433-Pengelolaan-Kinerja'

export const PANDUAN_PENGELOLAAN_BUKTI_DUKUNG = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40695122010777-Tentang-Variabel-Pengelolaan-Kinerja-Guru-Kepala-Sekolah-PKKS'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/28188813473689-Tentang-Bukti-Dukung-untuk-Kepala-Sekolah'

export const PANDUAN_PENGELOLAAN_POIN_PENGEMBANGAN_KOMPETENSI =
  USE_NEW_PANDUAN_URL
    ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40695122010777-Tentang-Variabel-Pengelolaan-Kinerja-Guru-Kepala-Sekolah-PKKS'
    : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26037247064217-Tentang-Poin-di-Pengembangan-Kompetensi'

export const PANDUAN_PENGELOLAAN_KENDALA_AKSES = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40449853228441-Kendala-Akses-Pengelolaan-Kinerja'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26332905719833'

// Used for Guru
export const PANDUAN_PENGELOLAAN_GURU_UNGGAH_BUKTI_DUKUNG_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40450791055385-Cara-Mengisi-Dokumen-Pengembangan-Kompetensi-dan-Pelaksanaan-Tugas-Pokok'
    : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26099265735193-Cara-Unggah-Bukti-Dukung-Pengembangan-Kompetensi-dan-Tambahan'
export const PANDUAN_PENGELOLAAN_GURU_BUKTI_DUKUNG_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40535479394713-Tentang-Variabel-Pengelolaan-Kinerja-Guru-PKG'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26099065712281-Tentang-Bukti-Dukung-Untuk-Guru'
export const PANDUAN_PENGELOLAAN_GURU_POIN_RHK_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40535479394713-Tentang-Variabel-Pengelolaan-Kinerja-Guru-PKG'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26037247064217-Tentang-Poin-di-Pengembangan-Kompetensi'
export const PANDUAN_PENGELOLAAN_GURU_PENGEMBANGAN_KOMPETENSI_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40450680960409-Tentang-Perencanaan-Kinerja-Guru'
    : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26037247064217'
export const PANDUAN_PENGELOLAAN_GURU_TUGAS_TAMBAHAN_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40535479394713-Tentang-Variabel-Pengelolaan-Kinerja-Guru-PKG'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26299452064409'
export const PANDUAN_PENGELOLAAN_GURU_SUBSTANSI_PENILAIAN_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://docs.google.com/presentation/d/1q-ICsZzoIwm2mbi4yKBWCiKnJVsY2SKoMXrnBeG4OTk'
    : 'https://docs.google.com/presentation/d/1TQE8zcO2CaKRd1vNFJfUpK-lMHkaTq1v4hSsFLeatD0'
export const PANDUAN_PENGELOLAAN_GURU_TUTORIAL_PENILAIAN_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://docs.google.com/presentation/d/1q-ICsZzoIwm2mbi4yKBWCiKnJVsY2SKoMXrnBeG4OTk'
    : 'https://www.youtube.com/watch?si=Cojn1FPX7kSS6ROR&v=wBl2hq0sLvo&feature=youtu.be'

// Used for Kepala Sekolah
export const PANDUAN_PENGELOLAAN_KS_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/sections/40466461635737-Pelaksanaan-Kinerja-Kepala-Sekolah'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/sections/28188517419929-Pelaksanaan-Kinerja-Kepala-Sekolah'
export const PANDUAN_PENGELOLAAN_GURU_PENILAIAN_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/sections/26037016738841-Penilaian-Pelaksanaan-Kinerja-Guru-oleh-Kepala-Sekolah'
  : 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/sections/40450614202009-Penilaian-Kinerja-Guru'
export const PANDUAN_PENGELOLAAN_KS_OBSERVASI_PRAKTIK_KINERJA_URL =
  'https://docs.google.com/presentation/d/1W3T3IVO1BSRoRuAVwOrzMw6rhk5L9cO1WoMhb7x5Cbw/edit?pli=1#slide=id.g26d28d43dac_0_0'
export const PANDUAN_PENGELOLAAN_GURU_BUKTI_DUKUNG_KS_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40451112594969-Tentang-Bukti-Dukung-Kepala-Sekolah-di-Pengelolaan-Kinerja-Guru-PKG'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/id/articles/29831473781401-Tentang-Bukti-Dukung-dalam-Pengelolaan-Kinerja-Guru-oleh-Kepala-Sekolah'
export const PANDUAN_PENGELOLAAN_GURU_UNGGAH_BUKTI_DUKUNG_KS_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40450791055385-Cara-Mengisi-Dokumen-Pengembangan-Kompetensi-dan-Pelaksanaan-Tugas-Pokok'
    : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/26100757442841'
export const PANDUAN_PENGELOLAAN_GURU_PENETAPAN_PREDIKAT_KINERJA_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40451552708633-Cara-Mengubah-dan-Mengirimkan-Penetapan-Predikat-Kinerja-Guru-Oleh-Kepala-Sekolah'
    : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/38335551469593-Cara-Penetapan-Predikat-Kinerja-Tahunan-Guru-Oleh-Kepala-Sekolah'
export const PANDUAN_PENGELOLAAN_GURU_TENTANG_PENILAIAN_URL =
  USE_NEW_PANDUAN_URL
    ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/sections/40450614202009-Penilaian-Kinerja-Guru'
    : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/33041039241881-Tentang-Penilaian-Kinerja-Guru-oleh-Kepala-Sekolah'

// Used for Kepala Dinas Pendidikan
export const PANDUAN_PENGELOLAAN_DINAS_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/sections/40466436787353-Penilaian-Kinerja-Kepala-Sekolah-oleh-Kepala-Dinas-Pendidikan'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/sections/31269867285273-Penilaian-Pelaksanaan-Kinerja-Kepala-Sekolah-oleh-Kepala-Dinas-Pendidikan'
export const PANDUAN_PENGELOLAAN_GURU_PENETAPAN_PKO_URL = USE_NEW_PANDUAN_URL
  ? 'https://pusatinformasi.ult.dikdasmen.go.id/hc/id/articles/40451608914457-Tentang-Penetapan-Predikat-Kinerja-Organisasi-Oleh-Kepala-Dinas-Pendidikan'
  : 'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/33041231276185-Tentang-Penetapan-Predikat-Kinerja-Organinsasi-Oleh-Kepala-Dinas-Pendidikan'
