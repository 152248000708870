import useToggleSdkContext from 'utils/useToogleSdk'
import usePeriodContext from 'app/KinerjaShared/hooks/usePeriodContext'

/**
 * Get new document flow status
 *
 * If the period year is greater than or equal to 2025, the new document flow is enabled.
 * It also checks if the new document flow is enabled in the toggle.
 */
export function useNewDocumentFlow() {
  const toggles = useToggleSdkContext()
  const { periodYear } = usePeriodContext()

  const isNewDocumentFlowEnabled =
    toggles?.['kinerja-new-document-flow-enabled'] || false
  const isPrior2025 = periodYear < 2025

  let status: 'DISABLED' | 'OLD_DOCUMENT_FLOW' | 'NEW_DOCUMENT_FLOW' =
    'DISABLED'

  if (isPrior2025) {
    status = 'OLD_DOCUMENT_FLOW'
  } else if (isNewDocumentFlowEnabled) {
    status = 'NEW_DOCUMENT_FLOW'
  }

  return {
    status,
  }
}
